import React from "react";
import { logo_navbar } from "../images";

const Navbar = () => {
    return (
        <div>
            <nav className="navbar navbar-light bg-light">
                <a className="navbar-brand" href="#">
                    <img src={logo_navbar} width="30" height="30" className="d-inline-block align-top" alt="" loading="lazy" />
                </a>
            </nav>
        </div>
    );
};

export default Navbar;