import React from "react";

const Links = () => {
    return (
        <div>
            <a role="button" href="https://medium.com/drusopsicologos" target="blank" className="btn btn-dark button-margin">Lee nuestros artículos en Medium</a>
            <a role="button" href="https://www.ivoox.com/podcast-druso-psicologos_sq_f11036710_1.html" target="blank" className="btn btn-dark button-margin">Escucha nuestro podcast</a>
            <a role="button" href="https://www.amazon.es/PSICOLOG%C3%8DA-APLICADA-HOSTELER%C3%8DA-TURISMO-fidelizar/dp/8490763216" target="blank" className="btn btn-outline-dark button-margin">Psicología aplicada en la Hostelería y el Turismo</a>
            <a role="button" href="https://www.amazon.es/Psicoecolog%C3%ADa-teor%C3%ADa-del-futuro-pasado/dp/8491757597" target="blank" className="btn btn-outline-dark button-margin">Psicoecología, teoría del futuro pasado</a>
            <a role="button" href="https://www.amazon.es/TRANS-AGE-%C2%BFCu%C3%A1l-verdadera-edad/dp/B0DLBG317Y" target="blank" className="btn btn-outline-dark button-margin">TRANS-AGE, ¿Cuál es tu verdadera edad?</a>
        </div>
    );
};

export default Links;