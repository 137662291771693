import React from "react";
import { Navbar, Header, Moto, Footer } from "./components";

//css
import "./App.css";

const App = () => {
  return (
    <div className="container-fluid">
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <Header />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6">
            <Moto />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
